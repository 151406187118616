import { Injectable } from '@angular/core';
import type { Either } from 'fp-ts/lib/Either';
import { left, right } from 'fp-ts/lib/Either';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ALPHA_AUTH_KEY, ALPHA_AUTH_VALUE, ALPHA_LOGIN_NAME, ALPHA_LOGIN_PASS } from './helpers';

@Injectable({
  providedIn: 'platform',
})
export class AlphaAuthService {
  /** Checks for authentication in the environment and in the cookies
   * @returns True when authenticated
   */
  public get isAuthed(): boolean {
    // Get the authentication cookie
    const authCookie = this._cookieService.get(ALPHA_AUTH_KEY);

    // Return whether the cookie is valid
    return authCookie === ALPHA_AUTH_VALUE;
  }

  constructor(private readonly _cookieService: SsrCookieService) {}

  /**
   * This is solely so we can keep our Alpha versions away from public eyes.
   *
   * We don't do real verifications here.
   *
   * Wraps the result inside an {@link Either}, on success it'll be void, otherwise the error that happened
   */
  public login(name: string, password: string): Either<Error, void> {
    if (name !== ALPHA_LOGIN_NAME || password !== ALPHA_LOGIN_PASS) {
      return left(new Error('Invalid credentials'));
    }

    // Set the authentication cookie
    this._cookieService.set(ALPHA_AUTH_KEY, ALPHA_AUTH_VALUE);
    return right(void 0);
  }
}

@Injectable({
  providedIn: 'platform',
})
/** Service to be inject outside alpha environment, `AlphaAuthService` should be never called in a non alpha environment, this is for safety  */
export class MockAlphaAuthService {
  /** Will always return true */
  public readonly isAuthed = true;

  /** Will never login the user because it's never called */
  public login(_name: string, _password: string): Either<Error, void> {
    console.error('Something really wrong is happening!!!');
    return right(void 0);
  }
}
