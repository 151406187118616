export const ICON_ID = {
  /* Arrow */
  arrow_down_right: 'arrow-down-right',
  arrow_link_up_right: 'arrow-link-up-right',
  arrow_page_left: 'arrow-page-left',
  arrow_page_right: 'arrow-page-right',
  dropdown_arrow: 'dropdown-arrow' /** WARNING: Not in the specs of v2 */,
  drag_arrows: 'drag-arrows',

  /* Social Media */
  instagram: 'instagram',
  linkedin: 'linkedin',
  spotify: 'spotify',
  youtube: 'youtube',

  /* Miscellaneous */
  link: 'link',
  info: 'info',
  rocket: 'rocket',
  hourglass: 'hourglass',
  building_left: 'building-left',
  building_right: 'building-right',
  expand: 'expand',
  close: 'close',
  flower_1: 'flower-1',
  flower_2: 'flower-2',
  flower_3: 'flower-3',
  warning: 'warning',

  /* Services */
  automated_testing: 'automated-testing',
  backend_development: 'backend-development',
  frontend_development: 'frontend-development',
  software_development: 'software-development',
  web_app_design: 'web-app-design',
  web_app_development_alt: 'web-app-development-alt',
  web_app_development: 'web-app-development',
  web_development: 'web-development',
  website_design: 'website-design',
  website_development_alt: 'website-development-alt',
  website_development: 'website-development',
  workflow_automation: 'workflow-automation',

  /* Technologies */
  angular: 'angular',
  clickup: 'clickup',
  cypress: 'cypress',
  docker: 'docker',
  express: 'express',
  figma: 'figma',
  github_actions: 'github-actions',
  javascript: 'javascript',
  jasmine: 'jasmine',
  linear: 'linear',
  mongo: 'mongo',
  ngrx: 'ngrx',
  node: 'node',
  nx: 'nx',
  typescript: 'typescript',
} as const;
