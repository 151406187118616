import { InjectionToken } from '@angular/core';
import type { ErrorModal, SuccessModal } from '@core-ui/interfaces';
import { EMOJIS } from './emojis';

export const MODAL_DATA_TOKEN = new InjectionToken<string>('MODAL_DATA');

/**
 * MODAL_PROPS_SUCCESS is a constant that contains all the data needed to create a modal
 *
 * @example
 * If you need to add different data, you may consider doing:
 *
 * ```ts
 * const modalReference = this._modalService.open(SuccessModalComponent,
 *  {
 *    ...MODAL_PROPS_SUCCESS,
 *    title: 'new title',
 *    description: 'new description'
 *  }
 * ```
 * */
export const MODAL_PROPS_SUCCESS: SuccessModal = {
  type: 'success',
  title: 'Check your email box',
  description:
    "We sent you an email with a link to schedule your free consultation meeting with our CEO. If you don't receive it, remember to check your spam box.",
  highlight: {
    color: 'green',
    emojiAlt: EMOJIS.partying_face.alt,
    emojiSrc: EMOJIS.partying_face.src,
    text: 'Great!',
  },
  ariaLabel: 'Form successfully submitted',
  buttonLabel: 'Ok',
};

/**
 * MODAL_PROPS_ERROR is a constant that contains all the data needed to create a modal
 *
 * @example
 * If you need to add different data, you may consider doing:
 *
 * ```ts
 * const modalReference = this._modalService.open(ErrorModalComponent,
 *  {
 *    ...MODAL_PROPS_ERROR,
 *    title: 'new title',
 *    description: 'new description'
 *  }
 * ```
 * */
export const MODAL_PROPS_ERROR: ErrorModal = {
  type: 'error',
  title: 'Something went wrong',
  description: 'We encountered an error while trying to complete this. Please, try again.',
  highlight: {
    color: 'orange',
    emojiAlt: EMOJIS.frowning_face_with_open_mouth.alt,
    emojiSrc: EMOJIS.frowning_face_with_open_mouth.src,
    text: 'Oops!',
  },
  ariaLabel: 'Failed to submit form',
  buttonLabel: 'Close',
};
