import { Injectable } from '@angular/core';
import type { CanActivate, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { AlphaAuthService } from '@unvoid/app/services/alpha-guard';
import { environment } from '@unvoid/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private readonly _alphaAuthService: AlphaAuthService, private readonly _router: Router) {}

  public canActivate(): true | UrlTree {
    // Guards the route in 'alpha' but not in 'development', this route is not accessible in 'production'
    if (environment.type === 'alpha' && this._alphaAuthService.isAuthed) {
      return this._router.createUrlTree(['/']);
    }
    return true;
  }
}
