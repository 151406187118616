import { NgModule } from '@angular/core';
import type { Route, Routes } from '@angular/router';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { environment } from '@unvoid/environments/environment';
import { BusinessFormGuard } from './guards/business-form';
import { ChatbotGuard } from './guards/chatbot';
import { LoginGuard } from './guards/login/login.guard';

const getTitle = (title: string): string => `Unvoid - ${title}`;

/** Routes available on production */
const MAIN_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    title: 'Unvoid',
    loadChildren: () => import('./routes/angular-routing.module').then(routing => routing.AngularRoutingModule),
    data: { animation: 'Home' },
  },
  {
    path: 'legal',
    title: getTitle('Legal'),
    loadChildren: () => import('./routes/legal-routing.module').then(routing => routing.LegalRoutingModule),
  },
  {
    path: 'case-studies',
    title: getTitle('Case Studies'),
    loadChildren: () => import('@case-studies').then(routing => routing.CaseStudiesRoutingModule),
  },
];

/** Routes available on production, alpha and development */
const ALPHA_ROUTES: Routes = [
  {
    path: 'login',
    title: getTitle('Login'),
    loadChildren: () => import('./routes/login-routing.module').then(routing => routing.LoginRoutingModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'home',
    title: 'Unvoid',
    loadChildren: () => import('./routes/home-routing.module').then(routing => routing.HomeRoutingModule),
    data: { animation: 'Home' },
  },
  {
    path: 'our-cases',
    title: getTitle('Case Studies'),
    loadChildren: () => import('@our-cases').then(routing => routing.OurCasesRoutingModule),
  },
  {
    path: 'links',
    title: getTitle('Links'),
    loadChildren: () => import('./routes/links-routing.module').then(routing => routing.LinksRoutingModule),
  },
  {
    // TODO(#476): When the issue is done, move this route to MAIN_ROUTES
    path: 'chat',
    title: getTitle('Chat'),
    loadChildren: () => import('./routes/chatbot-routing.module').then(routing => routing.ChatbotRoutingModule),
    canActivate: [ChatbotGuard],
    data: { animation: 'Chat' },
  },
  {
    // TODO(#476): When the issue is done, move this route to MAIN_ROUTES
    path: 'business',
    title: getTitle('Business'),
    loadChildren: () =>
      import('./routes/business-form-routing.module').then(routing => routing.BusinessFormRoutingModule),
    canActivate: [BusinessFormGuard],
    data: { animation: 'Business' },
  },
];

const NOT_FOUND_ROUTE: Route = {
  path: '**',
  title: getTitle('Not Found'),
  loadChildren: () => import('./routes/not-found-routing.module').then(routing => routing.NotFoundRoutingModule),
};

const getEnvironmentRules = (): Routes => {
  if (environment.alpha) {
    return ALPHA_ROUTES;
  }
  return [];
};

const routes = (): Routes => {
  return [
    ...MAIN_ROUTES,
    ...getEnvironmentRules(),
    // ? Not-Found route should always be the last route
    NOT_FOUND_ROUTE,
  ];
};

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes(), {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
})
export class AppRoutingModule {}
