export const PLACE_IN_LEFT = { transform: 'translateX(-100%)' } as const;

export const PLACE_IN_RIGHT = { transform: 'translateX(100%)' } as const;

export const PLACE_IN_CENTER = { transform: 'translate(0%, 0%)', opacity: 1 } as const;

export const PLACE_IN_BOTTOM = { transform: 'translateY(100%)' } as const;

export const PLACE_IN_MIDDLE = { transform: 'translateY(0%)' } as const;

export const PLACE_IN_TOP = { transform: 'translateY(-100%)' } as const;

export const FADED = { opacity: 0 } as const;

export const SLIDE_OUT_TIMING_FUNCTION = '400ms ease-out';
