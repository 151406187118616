import { animate, style } from '@angular/animations';
import type { AnimationStyle, TransitionMetadata } from '@unvoid-interfaces';
import { FADED, PLACE_IN_CENTER, SLIDE_OUT_TIMING_FUNCTION } from '../constants/animation';

// * Fade In Animation

/** Animates an element `entering` the page, where the element fade in to the page. */
export const generateFadeInAnimation = (optionalStyles?: AnimationStyle): TransitionMetadata => [
  style(FADED),
  animate(SLIDE_OUT_TIMING_FUNCTION, style({ ...PLACE_IN_CENTER, ...optionalStyles })),
];

/** Animates an element `entering` the page, where the element fade in to the page. */
export const generateFadeOutAnimation = (optionalStyles?: AnimationStyle): TransitionMetadata => [
  style(PLACE_IN_CENTER),
  animate(SLIDE_OUT_TIMING_FUNCTION, style({ ...FADED, ...optionalStyles })),
];
