import type { trigger } from '@angular/animations';
import { group, query, style, transition } from '@angular/animations';
import {
  generateSlideFromBottomAnimation,
  generateSlideFromLeftAnimation,
  generateSlideFromRightAnimation,
  generateSlideFromTopAnimation,
  generateSlideToBottomAnimation,
  generateSlideToLeftAnimation,
  generateSlideToRightAnimation,
  generateSlideToTopAnimation,
} from '@unvoid/app/animations/slides';
import { FADED } from '@unvoid/app/constants/animation';
import type { AnimationStyle, TransitionMetadata } from '@unvoid-interfaces';
import { generateFadeInAnimation, generateFadeOutAnimation } from '../animations/fade';

export const generateSlideAnimation = (
  direction: 'left' | 'right' | 'top' | 'bottom',
  additionalStyles?: AnimationStyle,
): {
  readonly enter: TransitionMetadata;
  readonly leave: TransitionMetadata;
} => {
  switch (direction) {
    // * Slides from `TOP` to `CENTER`
    case 'top': {
      return {
        enter: generateSlideFromTopAnimation(additionalStyles),
        leave: generateSlideToBottomAnimation(additionalStyles),
      };
    }
    // * Slides from `BOTTOM` to `CENTER`
    case 'bottom': {
      return {
        enter: generateSlideFromBottomAnimation(additionalStyles),
        leave: generateSlideToTopAnimation(additionalStyles),
      };
    }
    // * Slides from `LEFT` to `CENTER`
    case 'left': {
      return {
        enter: generateSlideFromLeftAnimation(additionalStyles),
        leave: generateSlideToRightAnimation(additionalStyles),
      };
    }
    // * Slides from `RIGHT` to `CENTER`
    default: {
      return {
        enter: generateSlideFromRightAnimation(additionalStyles),
        leave: generateSlideToLeftAnimation(additionalStyles),
      };
    }
  }
};

export const generateSlidePageTransition = (direction: 'left' | 'right' | 'top' | 'bottom'): TransitionMetadata => {
  const optional = { optional: true } as const;

  const { enter, leave } = generateSlideAnimation(direction);

  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'fixed',
          width: '100%',
        }),
      ],
      optional,
    ),
    group([query(':enter', enter), query(':leave', leave, optional)]),
  ];
};

export const generateSlideFadingAnimation = (
  direction: 'left' | 'right' | 'top' | 'bottom',
): Parameters<typeof trigger>[1] => {
  const { enter, leave } = generateSlideAnimation(direction, FADED);

  return [transition(':enter', enter), transition(':leave', leave)];
};

export const generateFadingAnimation = (): Parameters<typeof trigger>[1] => {
  const enter = generateFadeInAnimation();

  const leave = generateFadeOutAnimation();

  return [transition(':enter', enter), transition(':leave', leave)];
};
