export enum COMPANY_EMPLOYEES {
  startup = '1-9',
  business = '10-49',
  commerce = '50-249',
  enterprise = '250-999',
  corporation = '1000-9999',
  multinational = '10000+',
}

export type Company = {
  readonly id: number;
  readonly name: string;
  readonly website?: string | null;
  readonly location?: string;
  readonly employees?: COMPANY_EMPLOYEES;
  readonly about?: string | null;
};

export type CreatableCompany = {
  readonly name: string;
  readonly website?: string | null;
  readonly location?: string | null;
  readonly employees?: COMPANY_EMPLOYEES | null;
  readonly about?: string | null;
};

export type UpdatableCompany = {
  readonly id: number;
  readonly name?: string;
  readonly website?: string | null;
  readonly location?: string | null;
  readonly employees?: COMPANY_EMPLOYEES | null;
  readonly about?: string | null;
};

// TODO: move to utils
export const isCompany = (value: unknown): value is Company => {
  return value !== null;
};
