import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ApplicationInitStatus, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isString, isUndefined } from 'lodash-es';
import { GoogleTagManagerSettings } from './google-tag-manager.interfaces';
import { GOOGLE_TAG_MANAGER_CONFIG_TOKEN } from './google-tag-manager.module';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/naming-convention
  interface Window {
    // eslint-disable-next-line functional/prefer-readonly-type
    dataLayer: Array<Record<string, unknown>>;
  }
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  /**
   * Service initialization flag
   */
  private _isInitialized = false;

  /**
   * Stores pushed objects when service is not initialized
   */
  // eslint-disable-next-line functional/prefer-readonly-type
  private _postponedPushes: Array<Record<string, unknown>> = [];

  constructor(
    @Inject(PLATFORM_ID) private readonly _platformID: object,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(GOOGLE_TAG_MANAGER_CONFIG_TOKEN) private readonly _config: GoogleTagManagerSettings,
    private readonly _applicationInitStatus: ApplicationInitStatus,
  ) {
    this.postponedInitialization();
  }

  /**
   * Push any object to GTM dataLayer
   *
   * @param obj - object pushed to dataLayer
   */
  public push(object: Record<string, unknown>): void {
    if (this._isInitialized) {
      this._pushToDataLayer(object);
    } else {
      this._postponedPushes.push(object);
    }
  }

  /**
   * Waits for app initialization and then initialize module
   */
  public async postponedInitialization(): Promise<void> {
    await this._applicationInitStatus.donePromise;
    this._init();
  }

  /**
   * Initialize service - create dataLayer and setup necessary HTML code
   */
  private _init(): void {
    if (!this._config.isEnabled) {
      return;
    }

    if (isUndefined(this._config.gtmId)) {
      console.error('gtmService config is missing gtmId');
      return;
    }

    if (!this._isInitialized) {
      this._initDataLayer();
      this._pushToDataLayer({ 'gtm.start': Date.now(), 'event': 'gtm.js' });
      this._appendTags();
      this._pushPostponed();
    }
    this._isInitialized = true;
  }

  /**
   * Initialize global dataLayer variable
   */
  private _initDataLayer(): void {
    if (isPlatformBrowser(this._platformID)) {
      window.dataLayer = [];
    }
  }

  /**
   * Provide dataLayer (or noop one for server platform)
   */
  private _getDataLayer(): (typeof window)['dataLayer'] {
    return isPlatformBrowser(this._platformID) ? window.dataLayer : [];
  }

  /**
   * Push all postponed pushes to dataLayer
   */
  private _pushPostponed(): void {
    // eslint-disable-next-line functional/no-loop-statement
    for (const object of this._postponedPushes) {
      this._pushToDataLayer(object);
    }
    this._postponedPushes = [];
  }

  /**
   * Push any object to GTM dataLayer
   *
   * @param obj - object pushed to dataLayer
   */
  private _pushToDataLayer(object: Record<string, unknown>): void {
    if (this._config.isEnabled) {
      const dataLayer = this._getDataLayer();
      dataLayer.push(object);
    }
  }

  /**
   * Append necessary elements to DOM
   */
  private _appendTags(): void {
    const id = this._config.gtmId;
    if (isPlatformBrowser(this._platformID) && isString(id)) {
      this._document.head.prepend(this._buildScript(id));
      this._document.body.prepend(this._buildIframe(id));
    }
  }

  /**
   * Create GTM initialization script
   */
  private _buildScript(gtmId: string): HTMLElement {
    const scriptElement = this._document.createElement('script');
    scriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    scriptElement.async = true;
    return scriptElement;
  }

  /**
   * Create GTM noscript iframe
   */
  private _buildIframe(gtmId: string): HTMLElement {
    const iframeElement = this._document.createElement('iframe');
    iframeElement.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${gtmId}`);
    iframeElement.style.width = '0';
    iframeElement.style.height = '0';
    iframeElement.style.display = 'none';
    iframeElement.style.visibility = 'hidden';

    const noScriptElement = this._document.createElement('noscript');
    noScriptElement.innerHTML = iframeElement.outerHTML;

    return noScriptElement;
  }
}
