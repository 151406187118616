import { ViewportScroller } from '@angular/common';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ViewContainerRef } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { ModalService } from '@core-ui/services';
import { ROUTE_ANIMATIONS } from '@unvoid/app/animations/route';
import { isNil } from 'lodash-es';
import { filter } from 'rxjs';

@Component({
  selector: 'uv-app-root',
  templateUrl: './app.component.html',
  animations: [ROUTE_ANIMATIONS],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private _previousUrl?: string;
  private _currentUrl?: string;

  constructor(
    private readonly _viewContainerReference: ViewContainerRef,
    private readonly _modalService: ModalService,
    private readonly _router: Router,
    private readonly _viewportScroller: ViewportScroller,
  ) {}

  public ngOnInit(): void {
    this._modalService.setViewContainerReference(this._viewContainerReference);
    this._scrollManager();
  }

  /**
   * Replaces the scroll behavior configurations of RouterModule's
   * `scrollPositionRestoration` and `anchorScrolling`
   * for a better suited behavior for our app.
   */
  private _scrollManager(): void {
    this._router.events.pipe(filter((event): event is Scroll => event instanceof Scroll)).subscribe(event => {
      const { anchor, position, routerEvent } = event;
      this._previousUrl = this._currentUrl;
      [this._currentUrl] = routerEvent.url.split('?');

      // This prevents the scroll to top when changing the query params on the same page
      if (this._previousUrl === this._currentUrl) {
        return null;
      }

      if (!isNil(anchor)) {
        // Anchor navigation
        this._viewportScroller.scrollToAnchor(anchor);
        return null;
      }

      if (!isNil(position)) {
        // Backward navigation
        this._viewportScroller.scrollToPosition(position);
        return null;
      }

      // Forward navigation
      this._viewportScroller.scrollToPosition([0, 0]);
      return null;
    });
  }
}
