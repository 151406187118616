import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { InjectionToken, NgModule } from '@angular/core';
import type { GoogleTagManagerSettings } from './google-tag-manager.interfaces';
import { GoogleTagManagerService } from './google-tag-manager.service';

/**
 * Defines Injection token of `GtmConfig` for configuring module
 */
export const GOOGLE_TAG_MANAGER_CONFIG_TOKEN = new InjectionToken<GoogleTagManagerSettings>('GtmConfig');

/**
 * Import in App Module and pass `GtmConfig` object to `forRoot` method
 */
@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class GoogleTagManagerModule {
  /**
   * Creates and configures GTM module.
   *
   * @param config - An `GtmModule` object that specifies library configuration
   */
  public static forRoot(config: GoogleTagManagerSettings): ModuleWithProviders<GoogleTagManagerModule> {
    return {
      ngModule: GoogleTagManagerModule,
      providers: [
        GoogleTagManagerService,
        {
          provide: GOOGLE_TAG_MANAGER_CONFIG_TOKEN,
          useValue: config,
        },
      ],
    };
  }
}
