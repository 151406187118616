import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import type { Business, Contact } from '@unvoid-interfaces';
import { isBusiness } from '@unvoid-interfaces';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import { BehaviorSubject, firstValueFrom, ReplaySubject } from 'rxjs';

const BUSINESS_STORAGE_KEY = 'business';
@Injectable({
  providedIn: 'root',
})
// TODO: may replace with stores later
export class BusinessManagementService {
  private readonly _business$ = new ReplaySubject<O.Option<Business>>(1);
  public readonly business$ = this._business$.asObservable();

  private readonly _needToFillChatbot$ = new BehaviorSubject<boolean>(true);
  public readonly needToFillChatbot$ = this._needToFillChatbot$.asObservable();

  constructor(@Inject(PLATFORM_ID) platformID: object) {
    if (isPlatformBrowser(platformID)) {
      this._initBusiness();
    }
  }

  private readonly _initBusiness = (): void => {
    const businessO = pipe(
      BUSINESS_STORAGE_KEY,
      key => sessionStorage.getItem(key),
      O.fromNullable,
      O.map(business => JSON.parse(business) as unknown),
      O.chain(O.fromPredicate(isBusiness)),
    );

    if (O.isSome(businessO)) {
      this._needToFillChatbot$.next(false);
    }

    this._business$.next(businessO);
  };

  public saveInitialBusinessData = (business: Business | null): void => {
    sessionStorage.setItem(BUSINESS_STORAGE_KEY, JSON.stringify(business));
    this._business$.next(O.fromNullable(business));
  };

  public updateBusinessData = async (updatedBusiness: Partial<Business>): Promise<void> => {
    const currentBusinessO = await firstValueFrom(this.business$);

    pipe(
      currentBusinessO,
      O.map(currentBusiness => {
        const business = {
          ...currentBusiness,
          ...updatedBusiness,
        };

        sessionStorage.setItem(BUSINESS_STORAGE_KEY, JSON.stringify(business));
        this._business$.next(O.fromNullable(business));

        return business;
      }),
    );
  };

  public addContact = async (newContact: Contact): Promise<void> => {
    const currentBusinessO = await firstValueFrom(this.business$);

    pipe(
      currentBusinessO,
      O.map(({ contacts, ...currentBusiness }) => {
        const business = {
          ...currentBusiness,
          contacts: [...contacts, newContact],
        };

        sessionStorage.setItem(BUSINESS_STORAGE_KEY, JSON.stringify(business));
        this._business$.next(O.fromNullable(business));

        return business;
      }),
    );
  };

  public updateContact = async (updatedContact: Contact): Promise<void> => {
    const currentBusinessO = await firstValueFrom(this.business$);

    pipe(
      currentBusinessO,
      O.map(({ contacts, ...currentBusiness }) => {
        const business = {
          ...currentBusiness,
          contacts: contacts.map(contact => (contact.id === updatedContact.id ? updatedContact : contact)),
        };

        sessionStorage.setItem(BUSINESS_STORAGE_KEY, JSON.stringify(business));
        this._business$.next(O.fromNullable(business));

        return business;
      }),
    );
  };

  public deleteContact = async (contactID: Contact['id']): Promise<void> => {
    const currentBusinessO = await firstValueFrom(this.business$);

    pipe(
      currentBusinessO,
      O.map(({ contacts, ...currentBusiness }) => {
        const business = {
          ...currentBusiness,
          contacts: contacts.filter(contact => contact.id !== contactID),
        };

        sessionStorage.setItem(BUSINESS_STORAGE_KEY, JSON.stringify(business));
        this._business$.next(O.fromNullable(business));

        return business;
      }),
    );
  };

  public reset = (): void => {
    sessionStorage.removeItem(BUSINESS_STORAGE_KEY);
    this._needToFillChatbot$.next(true);
  };

  public markChatbotAsCompleted = (): void => {
    this._needToFillChatbot$.next(false);
  };
}
