import type { Company } from './company';
import type { Contact, Email } from './contact';
import type { Project, PROJECT_BUDGET, PROJECT_STAGE_TYPE } from './project';

/** The required information for the first contact between us and the client */
export type BusinessRequiredInformation = {
  readonly name: string;
  readonly email: Email;
  readonly budget: PROJECT_BUDGET;
  readonly stage: PROJECT_STAGE_TYPE;
  readonly nonDisclosureAgreement: boolean;
  readonly description: string;
};

export type BusinessInitialInformation = {
  readonly name: string;
  readonly email: Email;
};

export type BusinessPartialInformation = {
  readonly name: string;
  readonly email: Email;
  readonly budget?: PROJECT_BUDGET;
  readonly stage?: PROJECT_STAGE_TYPE;
  readonly nonDisclosureAgreement?: boolean;
  readonly description?: string;
};

export type Business = {
  readonly ownerID: Contact['id'];
  readonly contacts: ReadonlyArray<Contact>;
  readonly company: Company;
  readonly project: Project;
};

// TODO: move to utils
export const isBusiness = (value: unknown): value is Business => {
  return value !== undefined;
};
