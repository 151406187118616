import { NgModule } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AlphaAuthService, MockAlphaAuthService } from '@unvoid/app/services/alpha-guard/alpha-auth.service';
import { environment } from '@unvoid/environments/environment';
import { startsWith } from 'lodash-es';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

@NgModule({
  providers: [
    {
      provide: AlphaAuthService,
      useFactory: (cookieService: SsrCookieService) => {
        if (environment.alpha) {
          return new AlphaAuthService(cookieService);
        }

        /** Non-Alpha environments will never import this module, but I keeping that for safety  */
        return new MockAlphaAuthService();
      },
      deps: [SsrCookieService],
    },
  ],
})
/**
 * Guards the deployed app in alpha from the public and redirect them to the login page
 */
export class AlphaModule {
  constructor(private readonly _alphaAuthService: AlphaAuthService, private readonly _router: Router) {
    // Guards all routes in 'alpha' but not in 'development', this module is not accessible in 'production'
    if (environment.type === 'alpha') {
      this._guardRoutesFromPublic();
    }
  }

  private _canActivate(url: string): boolean {
    if (startsWith(url, '/login')) {
      return true;
    }
    return this._alphaAuthService.isAuthed;
  }

  private async _guardRoutesFromPublic(): Promise<void> {
    const routerEvent = await firstValueFrom(
      this._router.events.pipe(filter((event): event is NavigationStart => event instanceof NavigationStart)),
    );
    if (this._canActivate(routerEvent.url)) {
      return;
    }
    await this._router.navigate(['/', 'login'], { queryParams: { redirectToURL: routerEvent.url } });
  }
}
