export enum PROJECT_BUDGET {
  initial = '25-50',
  small = '50-200',
  medium = '200-500',
  unlimited = '+500',
}

export enum PROJECT_STAGE_TYPE {
  new = 'New Project',
  existing = 'Existing Project',
  augmentation = 'Team Augmentation',
}

export type Project = {
  readonly id: string;
  readonly stage: PROJECT_STAGE_TYPE;
  readonly budget: PROJECT_BUDGET;
  readonly description: string;
  readonly nonDisclosureAgreement: boolean;
  readonly deadlineDate: string | null;
  readonly techStack: string | null;
};

export type CreatableProject = {
  readonly stage: PROJECT_STAGE_TYPE | null;
  readonly budget: PROJECT_BUDGET | null;
  readonly description?: string | null;
  readonly nonDisclosureAgreement?: boolean | null;
  readonly deadlineDate: string | null;
  readonly techStack: string | null;
};

export type UpdatableProject = {
  readonly id: string;
  readonly stage: PROJECT_STAGE_TYPE | null;
  readonly budget: PROJECT_BUDGET | null;
  readonly description?: string | null;
  readonly nonDisclosureAgreement?: boolean | null;
  readonly deadlineDate: string | null;
  readonly techStack: string | null;
};

export const isProject = (value: unknown): value is Project => {
  return value !== null;
};

/** The smallest date (in days) of the deadline field */
export const DEADLINE_MINIMUM_DAYS = 30; // One month

/** The biggest date (in days) of the deadline field */
export const DEADLINE_MAXIMUM_DAYS = 365 * 5; // Five years
