import { transition, trigger } from '@angular/animations';
import { generateSlidePageTransition } from '@unvoid/app/helpers/animation';

/**
 * An animation used when a `Router` changes occurs.
 */
export const ROUTE_ANIMATIONS = trigger('routeAnimations', [
  transition('Home => Chat', generateSlidePageTransition('right')),
  transition('Chat => Home', generateSlidePageTransition('left')),
  transition('Chat => Business', generateSlidePageTransition('bottom')),
]);
