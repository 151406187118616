import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { SERVER_APP_ID } from '@unvoid/app/constants/server.constants';
import { environment } from '@unvoid/environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AlphaModule } from './guards/alpha/alpha.module';
import { TrackingModule } from './providers/tracking/tracking.module';
import { GoogleTagManagerModule } from './services/google-tag-manager';

// TODO (alpha): might a better way to do that ??? using factory may
const ALPHA_MODULES = environment.alpha ? [AlphaModule] : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: SERVER_APP_ID }),
    TransferHttpCacheModule,
    HttpClientModule, // TODO: modularize the `app/clients` services
    AppRoutingModule,
    BrowserAnimationsModule,
    OverlayModule,
    TrackingModule.forRoot(),
    GoogleTagManagerModule.forRoot({
      gtmId: environment.googleTagManagerID,
      isEnabled: environment.type === 'production', // GTM should not be active outside 'production' environment
    }),

    ...ALPHA_MODULES,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
