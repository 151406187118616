import { Injectable } from '@angular/core';
import type { CanActivate, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { BusinessManagementService } from '@unvoid/app/services/business-management';
import * as O from 'fp-ts/lib/Option';
import type { Observable } from 'rxjs';
import { combineLatest, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusinessFormGuard implements CanActivate {
  constructor(private readonly _businessManagement: BusinessManagementService, private readonly _router: Router) {}

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([this._businessManagement.needToFillChatbot$, this._businessManagement.business$]).pipe(
      map(([needToFillChatbot, business]) => {
        return !needToFillChatbot && O.isSome(business) ? true : this._router.createUrlTree(['/']);
      }),
    );
  }
}
