/**
 * The naming convention for this type is:
 *
 * @example
 * ```ts
 * type EmojiID = 'backhand_index_pointing_down'
 * ```
 *
 * 1) Please use the name with underscore!
 * 2) The name must match the file name.
 * 3) The filename MUST have dashes to separate the words! (If needed)
 * @example
 * ```ts
 * 'backhand-index-pointing-down.png'
 * ```
 */
export type EmojiID =
  | 'backhand_index_pointing_down'
  | 'flushed_face'
  | 'frowning_face_with_open_mouth'
  | 'glowing_star'
  | 'handshake'
  | 'mage'
  | 'partying_face'
  | 'rocket'
  | 'sparkles'
  | 'waving_hand'
  | 'winking_face'
  | 'worried_face';

/**
 * This will replace all the underscore in EmojiID with the dash
 *
 * e.g.:
 *```ts
 * 'worried_face' // To 'worried-face';
 * ```
 *
 */
type ReplaceWithHifen<T> = T extends `${infer A}_${infer B}` ? ReplaceWithHifen<`${A}-${B}`> : T;
type Extension = '.png';

/**
 * Create an object with all the emojis sources and alternative texts
 */
export type Emojis = {
  readonly [K in EmojiID]: {
    readonly src: `/assets/emojis/${ReplaceWithHifen<K>}${Extension}`;
    readonly alt: string;
  };
};

export const EMOJIS: Emojis = {
  backhand_index_pointing_down: {
    src: '/assets/emojis/backhand-index-pointing-down.png',
    alt: '👇',
  },
  flushed_face: {
    src: '/assets/emojis/flushed-face.png',
    alt: '😳',
  },
  frowning_face_with_open_mouth: { src: '/assets/emojis/frowning-face-with-open-mouth.png', alt: '😦' },
  glowing_star: {
    src: '/assets/emojis/glowing-star.png',
    alt: '🌟',
  },
  handshake: {
    src: '/assets/emojis/handshake.png',
    alt: '🤝',
  },
  mage: {
    src: '/assets/emojis/mage.png',
    alt: '🧙',
  },
  partying_face: { src: '/assets/emojis/partying-face.png', alt: '🥳' },
  rocket: {
    src: '/assets/emojis/rocket.png',
    alt: '🚀',
  },
  sparkles: {
    src: '/assets/emojis/sparkles.png',
    alt: '✨',
  },
  waving_hand: {
    src: '/assets/emojis/waving-hand.png',
    alt: '👋',
  },
  winking_face: {
    src: '/assets/emojis/winking-face.png',
    alt: '😉',
  },
  worried_face: {
    src: '/assets/emojis/worried-face.png',
    alt: '😟',
  },
} as const;
