import { animate, style } from '@angular/animations';
import { PLACE_IN_LEFT, PLACE_IN_TOP, SLIDE_OUT_TIMING_FUNCTION } from '@unvoid/app/constants/animation';
import type { AnimationStyle, TransitionMetadata } from '@unvoid-interfaces';
import { PLACE_IN_BOTTOM, PLACE_IN_CENTER, PLACE_IN_RIGHT } from '../constants/animation';

//* Left Animation

/** Animates an element `leaving` the page, where the element slide out to the left. */
export const generateSlideToLeftAnimation = (optionalStyles?: AnimationStyle): TransitionMetadata => [
  style(PLACE_IN_CENTER),
  animate(SLIDE_OUT_TIMING_FUNCTION, style({ ...PLACE_IN_LEFT, ...optionalStyles })),
];

/** Animates an element `leaving` the page, where the element slide out to the left. */
export const generateSlideFromLeftAnimation = (optionalStyles?: AnimationStyle): TransitionMetadata => [
  style({ ...PLACE_IN_LEFT, ...optionalStyles }),
  animate(SLIDE_OUT_TIMING_FUNCTION, style(PLACE_IN_CENTER)),
];

// * Right Animation

/** Animates an element `entering` the page, where the element 'slide-in' from the right. */
export const generateSlideFromRightAnimation = (optionalStyles?: AnimationStyle): TransitionMetadata => [
  style({ ...PLACE_IN_RIGHT, ...optionalStyles }),
  animate(SLIDE_OUT_TIMING_FUNCTION, style(PLACE_IN_CENTER)),
];

/** Animates an element `leaving` the page, where the element slide out to the right. */
export const generateSlideToRightAnimation = (optionalStyles?: AnimationStyle): TransitionMetadata => [
  style(PLACE_IN_CENTER),
  animate(SLIDE_OUT_TIMING_FUNCTION, style({ ...PLACE_IN_RIGHT, ...optionalStyles })),
];

// * Bottom Animation

/** Animates an element `entering` in the page, where the element slide in from the bottom. */
export const generateSlideFromBottomAnimation = (optionalStyles?: AnimationStyle): TransitionMetadata => [
  style({ ...PLACE_IN_BOTTOM, ...optionalStyles }),
  animate(SLIDE_OUT_TIMING_FUNCTION, style(PLACE_IN_CENTER)),
];

/** Animates an element `leaving` in the page, where the element slide out to the bottom. */
export const generateSlideToBottomAnimation = (optionalStyles?: AnimationStyle): TransitionMetadata => [
  style(PLACE_IN_CENTER),
  animate(SLIDE_OUT_TIMING_FUNCTION, style({ ...PLACE_IN_BOTTOM, ...optionalStyles })),
];

// * Top animation

/** Animates an element `leaving` in the page, where the element slide out to the top. */
export const generateSlideToTopAnimation = (optionalStyles?: AnimationStyle): TransitionMetadata => [
  style(PLACE_IN_CENTER),
  animate(SLIDE_OUT_TIMING_FUNCTION, style({ ...PLACE_IN_TOP, ...optionalStyles })),
];

/** Animates an element `entering` in the page, where the element slide in from the top. */
export const generateSlideFromTopAnimation = (optionalStyles?: AnimationStyle): TransitionMetadata => [
  style({ ...PLACE_IN_TOP, ...optionalStyles }),
  animate(SLIDE_OUT_TIMING_FUNCTION, style(PLACE_IN_CENTER)),
];
