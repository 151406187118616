import { CommonModule, isPlatformBrowser } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { ErrorHandler, Inject, InjectionToken, NgModule, PLATFORM_ID } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from 'apps/unvoid-www/src/environments/environment';
import { isUndefined } from 'lodash-es';

const TRACKING_FOR_ROOT = new InjectionToken<boolean>('');

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class TrackingModule {
  public static forRoot(): ModuleWithProviders<TrackingModule> {
    return {
      ngModule: TrackingModule,
      providers: [
        { provide: TRACKING_FOR_ROOT, useValue: true },
        { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: true }) },
      ],
    };
  }

  constructor(@Inject(PLATFORM_ID) private readonly _platformID: object, @Inject(TRACKING_FOR_ROOT) forRoot: boolean) {
    if (forRoot) {
      this._setupSentry();
    }
  }

  private _setupSentry(): void {
    // Sentry is not used outside 'production' environment
    if (environment.type !== 'production' || !isPlatformBrowser(this._platformID) || isUndefined(environment.sentry)) {
      return;
    }

    const integrations: Sentry.BrowserOptions['integrations'] = [];
    if (environment.sentry.tracesSampleRate > 0) {
      integrations.push(
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      );
    }

    Sentry.init({
      dsn: environment.sentry.dsn,
      environment: environment.type,
      autoSessionTracking: true,

      // Undefined is the same as 0, meaning Sentry.io will not trace errors
      tracesSampleRate: environment.sentry.tracesSampleRate,
      integrations,
    });
  }
}
